.nav-item {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: 'Fredoka', serif;
  font-size: 17px;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: #7BB4F0;
  position: relative;
}

.logo {
}

.menu-icon {
  display: none;
}

.nav-elements {
  z-index: 1;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a:hover {
  font-size: 17px;
  font-weight: 600;
  color: #2b5b94;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #2b5b94;
  font-weight: 600;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2b5b94;
}

@media (max-width: 952px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 952px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #f4f4f4;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}