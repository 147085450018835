.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }

  .login__google {
    background-color: #FFFFFF;
    color: #333333;
    font-weight: 600;
    font-family: 'Fredoka';
    padding: 16px 32px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 20px #00000030;
    transition: 0.2s cubic-bezier(0.44, 0.13, 0.48, 0.87);
  }
  
  .login-player {
    width: 80%;
    max-width: 400px;
    padding-bottom: 124px;
  }

  .login__google:hover {
    background-color: #000000;
    color: #FFFFFF;
  }

  .login div {
    margin-top: 7px;
  }

  .button-img {
    height: 20px;
    pointer-events: none;
    vertical-align: text-top;
    padding-right: 16px;
  }

  @keyframes slide {
    0% {
      left: 0;
      top: 0;
    }
    50% {
      left: 244px;
      top: 100px;
    }
    100% {
      left: 488px;
      top: 0;
    }
  }